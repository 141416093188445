import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {View} from 'react-native'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {ProgressTracker} from 'src/designSystem/components/molecules/ProgressTracker/ProgressTracker'
import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Separator from 'src/designSystem/components/atoms/Separator/Separator'
import {SubscriptionLegalize} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptSubscriptionAgreement/SubscriptionLegalize'
import {useSteps} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptSubscriptionAgreement/useSteps'

export type AcceptSubscriptionAgreementTemplateProps = {
  onPreReqFulfilled: () => void | Promise<void>
  amountApproved?: string
  testID?: string
}

/**
 * Container to use the AcceptAutoPayAgreement screen within Workflows.
 */
const AcceptSubscriptionAgreementTemplate: React.FC<AcceptSubscriptionAgreementTemplateProps> = (
  props: AcceptSubscriptionAgreementTemplateProps,
) => {
  const {onPreReqFulfilled, amountApproved, testID} = props
  const [isSelected, setIsSelected] = React.useState<boolean>(false)
  const [isBusy, setIsBusy] = React.useState<boolean>(false)
  const handleOnAcceptAndContinue = useCallback(async () => {
    setIsBusy(true)
    await onPreReqFulfilled()
    setIsBusy(false)
  }, [onPreReqFulfilled])

  const {t} = useTranslation('Advance')

  // const {getSubscriptionAgreementDocument, getSubscriptionAutoPayAgreementDocument} =
  //   useGetAgreementDocument()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.accept_agreements_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
    eventArgs: {
      navContainerType: 'Workflows',
      agreements: 'subscription',
    },
  })

  const steps = useSteps(t)

  const handleMembershipAgreementLink = useCallback(() => {
    //const link = getSubscriptionAgreementDocument()
    // openLink(link)
  }, [])

  const handlePaymentAuthLink = useCallback(() => {
    //const link = getSubscriptionAutoPayAgreementDocument()
    // openLink(link)
  }, [])

  return (
    <Page
      testID={testID}
      variant={'generic'}
      buttonProps={buttonLockupProperties({
        text: t('ActivateAndContinue'),
        testID: 'LoanPaymentReviewContinueButton',
        onPress: handleOnAcceptAndContinue,
        disabled: !isSelected || isBusy,
      })}
      title={t('ActivateYourMembership')}
      smallTopGap
      description={t('ActivateAndGetAccessToAdvances')}
    >
      <View>
        <Box align="center">
          <PFText variant="p_lg_semibold">{t('YourFirstAdvance')}</PFText>
          <PFText variant="d2" color={NamedColors.PRODUCT_BLUE}>
            {amountApproved}
          </PFText>
          <PFText variant="p" color={NamedColors.SILVER}>
            {t('PayInFour')}
          </PFText>
        </Box>
        <View style={{marginTop: 8}}>
          <ProgressTracker steps={steps} />
        </View>
        <Separator />
        <SubscriptionLegalize
          onSetSelected={setIsSelected}
          onMembershipAgreementLink={handleMembershipAgreementLink}
          onPaymentAuthLink={handlePaymentAuthLink}
        />
      </View>
    </Page>
  )
}

export {AcceptSubscriptionAgreementTemplate}
