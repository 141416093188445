import {LaunchArgProcessorsType} from 'src/lib/LaunchArgs/LaunchArgs.types'

let GitBranchOverride: string | undefined = undefined
const GetGitBranchOverride = (): string | undefined => {
  return GitBranchOverride
}

const GitBranchLaunchArgProcessor: LaunchArgProcessorsType['gitBranchName'] = (value) => {
  GitBranchOverride = value
}

export {GetGitBranchOverride, GitBranchLaunchArgProcessor}
