import {ApolloError} from '@apollo/client'

import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

import {AccountListQuery, AccountListDocument} from 'src/lib/bank/queries/AccountList.gqls'

export type UsePreferredAccountResult = {
  value: NonNullable<AccountListQuery['me']['bankAccounts']['all']>[number] | undefined
  preferredAccountError: ApolloError | undefined
  preferredAccountRefetch: ReturnType<typeof useCassandraQuery<AccountListQuery>>['refetch']
  isLoading: boolean
}

export const usePreferredAccount = (): UsePreferredAccountResult => {
  const {
    data,
    loading: isLoading,
    error: preferredAccountError,
    refetch: preferredAccountRefetch,
  } = useCassandraQuery(AccountListDocument)
  const value = findPreferredAccount(data?.me.bankAccounts.all)
  return {value, isLoading, preferredAccountError, preferredAccountRefetch}
}
export type AllAccountsType = AccountListQuery['me']['bankAccounts']['all']
export type PreferredAccountType = NonNullable<AllAccountsType>[number]

export const findPreferredAccount = (
  allAccounts: AllAccountsType | undefined,
): PreferredAccountType | undefined => {
  return allAccounts?.find((account) => account.preferredFundingSource)
}
