import React, {useRef} from 'react'
import {StatusBar, StyleSheet, View} from 'react-native'
import {useTranslation} from 'react-i18next'
import {useFormContext} from 'react-hook-form'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import {SafeAreaView} from 'react-native-safe-area-context'
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'

import {NamedColors} from 'src/designSystem/colors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {EmailPasswordForm} from 'src/products/MCU/components/organisms/EmailPasswordForm/EmailPasswordForm'
import VersionFooter from 'src/products/general/VersionFooter/VersionFooter'
import {LoginRegistrationLegal} from 'src/products/MCU/RegistrationOrLogin/LoginRegistrationLegal'
import {RegistrationHeader} from 'src/products/MCU/RegistrationOrLogin/Registration/RegistrationHeader'
import {defaultExtraHeightOffset} from 'src/designSystem/guide'
import {largeGap, pageMarginHorizontal, smallGap} from 'src/designSystem/layout'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {UserSocialLoginFunction} from 'src/products/MCU/RegistrationOrLogin/Login/Login.types'
import {GoogleLoginButton} from 'src/products/MCU/RegistrationOrLogin/LoginButtons'
import {UserRegisterInput} from '@possible/cassandra/src/types/types.public.generated'

type RegistrationTemplateProps = {
  onHandleSignUp: (data: UserRegisterInput) => void
  onHandleTapLogin: () => void
  onHandleSocialLogin: UserSocialLoginFunction
  isSocialBusy: boolean
  isSubmittingForm: boolean
}

const RegistrationTemplate: React.FC<RegistrationTemplateProps> = (props): JSX.Element => {
  const {
    onHandleSignUp: handleSignUp,
    onHandleTapLogin: handleTapLogin,
    onHandleSocialLogin: handleSocialLogin,
    isSocialBusy,
    isSubmittingForm,
  } = props
  const navigation = useNavigation<StackNavigationProp<MainStackParamList, 'Registration'>>()

  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
  } = useFormContext<UserRegisterInput>()

  const {t} = useTranslation(['Common', 'RegistrationOrLogin'])
  const keyboardViewRef = useRef<KeyboardAwareScrollView>(null)
  const action = {
    onSubmit: handleSubmit(handleSignUp),
    text: t('RegistrationOrLogin:CreateAccount'),
  }

  return (
    <View style={styles.containerView}>
      <StatusBar barStyle={'light-content'} />
      <KeyboardAwareScrollView
        ref={keyboardViewRef}
        style={styles.scrollView}
        contentContainerStyle={styles.contentContainerStyle}
        extraHeight={defaultExtraHeightOffset}
        scrollEventThrottle={1}
        keyboardShouldPersistTaps={'handled'}
        testID={'Keyboard-Aware-Scroll-View'}
      >
        <>
          <RegistrationHeader />
          <SafeAreaView edges={['bottom', 'right', 'left']} style={styles.safeAreaView}>
            <View style={styles.contentView}>
              <GoogleLoginButton
                onHandleSocialLogin={handleSocialLogin}
                isBusy={isSocialBusy}
                isDisabled={isSubmittingForm}
                marginTop={'tiny'}
              />
              <EmailPasswordForm
                shouldIncludeStateField
                showForgotLinks={false}
                showRequirements
                isSubmittingForm={isSubmittingForm}
                action={action}
                control={control}
                errors={errors}
                navigation={navigation}
                watch={watch}
              />
            </View>
            <Box marginBottom={smallGap} marginHorizontal={largeGap} align={'center'}>
              <SvgLink
                accessibilityLabel="Sign-Up-Login-Link"
                linkIcon={'internal'}
                linkIconSize={'little'}
                linkText={t('RegistrationOrLogin:AlreadyAMember')}
                linkType={'single'}
                onPress={handleTapLogin}
                testID={'Sign-Up-Login-Link'}
                textTransform={'capitalize'}
                textVariant={'label_md'}
              />
            </Box>
            <View style={styles.contentView}>
              <PFText variant={'p_sm'} textAlign={'center'}>
                {t('RegistrationOrLogin:RegistrationV2FooterText')}
              </PFText>
              <LoginRegistrationLegal disabled={false} />
              <VersionFooter />
            </View>
          </SafeAreaView>
        </>
      </KeyboardAwareScrollView>
    </View>
  )
}
export {RegistrationTemplate}

const contentMaxWidth: number = 450

const styles = StyleSheet.create({
  containerView: {
    backgroundColor: NamedColors.BLACK,
    flex: 1,
    width: '100%',
  },
  contentContainerStyle: {
    flexGrow: 1,
    width: '100%',
  },
  contentView: {
    alignSelf: 'center',
    maxWidth: contentMaxWidth,
    paddingVertical: smallGap,
    paddingHorizontal: pageMarginHorizontal,
    width: '100%',
  },
  safeAreaView: {
    backgroundColor: NamedColors.WHITE,
    flex: 1,
  },
  scrollView: {
    flex: 1,
    width: '100%',
  },
})
