import React from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {
  ActionItemProps,
  ActionList,
} from 'src/products/loans/LoanApplicationSubmission/ActionList/ActionList'
import {prefixSsn} from 'src/lib/utils/ssnUtil'
import {NamedColors} from 'src/designSystem/colors'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {formatDate} from 'src/lib/utils/date'
import {APIDateFormat, apiParseDateFormat} from 'src/lib/time_util'
import {AdvanceMonthlyFee} from 'src/lib/advance/CashAdvanceUtils'

export type LoanSubmissionTemplateProps = {
  isSubmitBtnLoading: boolean
  isSubmitBtnDisabled: boolean
  loanAmount: string
  fullName: string
  email: string
  bankAccount: string
  birthDate: string
  address: string
  ssnLastFour: string
  canUpdateAddress: boolean
  onSubmitLoanApplication: () => void | Promise<void>
  onPressContactUs: () => void
  onPressEdit?: (fieldName: string) => void
  isAdvance?: boolean
  isAppRevampFunctionalUpdatesEnabled?: boolean
}

/**
 * Template for the LoanSubmission screen to apply and reapply for a loan. Displays a summary of their personal information with a button to confirm
 * and submit their loan application.
 */
const LoanSubmissionTemplate: React.FC<LoanSubmissionTemplateProps> = (
  props: LoanSubmissionTemplateProps,
) => {
  const {
    isSubmitBtnDisabled,
    isSubmitBtnLoading,
    loanAmount,
    fullName,
    email,
    bankAccount,
    birthDate,
    address,
    ssnLastFour,
    canUpdateAddress,
    onSubmitLoanApplication,
    onPressContactUs: handleOnPressContactUs,
    onPressEdit,
    isAdvance,
    isAppRevampFunctionalUpdatesEnabled = false,
  } = props

  const {t} = useTranslation(['LoanSubmission', 'Common'])

  // If onPressEdit is not provided, undefined means the action button will not be rendered
  const handleOnPressEdit = (fieldName: string): (() => void) | undefined =>
    onPressEdit ? (): void => onPressEdit(fieldName) : undefined

  const costItem = isAdvance
    ? {
        title: t('Common:FlatMonthlyMembership'),
        text: `$${AdvanceMonthlyFee}`,
      }
    : {
        title: t('Common:AmountRequested'),
        text: `$${loanAmount}`,
        handleOnEdit: handleOnPressEdit('loanAmount'),
      }

  const actionItems: ActionItemProps[] = [
    {
      title: t('Common:Name'),
      text: fullName,
      handleOnPress: handleOnPressEdit('name'),
    },
    costItem,
    {
      title: t('Common:Email'),
      text: email,
    },
    {
      title: t('LoanSubmission:BankAccount'),
      text: bankAccount,
    },
    {
      title: t('Common:BirthDate'),
      text: formatDate(birthDate, APIDateFormat, apiParseDateFormat),
      handleOnPress: handleOnPressEdit('birthDate'),
    },
    {
      title: t('SSN'),
      text: prefixSsn(ssnLastFour),
      handleOnPress: handleOnPressEdit('ssn'),
    },
    {
      title: t('Common:Address'),
      text: address,
      handleOnPress: handleOnPressEdit('address'),
      actionIcon: canUpdateAddress ? 'edit' : 'info',
    },
  ]

  return (
    <Page
      variant="generic"
      smallTopGap={true}
      noHorizontalPadding
      title={t('ReviewAndSubmit')}
      buttonProps={{
        type: 'singleButton',
        primary: {
          testID: 'LoanSubmissionTemplate-submit-btn',
          text: t('SubmitApplication'),
          onPress: () => onSubmitLoanApplication(),
          loading: isSubmitBtnLoading,
          disabled: isSubmitBtnDisabled,
        },
      }}
    >
      <ActionList items={actionItems} separatorType={'between'} />
      <Box gap="small" margin={'medium'}>
        {!isAppRevampFunctionalUpdatesEnabled ? (
          <Box
            border={{width: 1, color: NamedColors.ASH}}
            radius={4}
            padding={'small'}
            direction={'row'}
            gap="tiny"
          >
            <Box marginRight={'tiny'}>
              <SvgIcon size={'medium'} colorVariant={'info'} isFilled name={'profile'} />
            </Box>
            <PFText variant="p" color={'info'} onPress={handleOnPressContactUs}>
              {t('Common:ContactUs')}
              <PFText variant="p"> {t('ToUpdateYourInfo')}</PFText>
            </PFText>
          </Box>
        ) : null}

        <PFText variant="p_sm" color={NamedColors.SILVER}>
          {t('SsnDisclosure')}
        </PFText>
      </Box>
    </Page>
  )
}

export {LoanSubmissionTemplate}
