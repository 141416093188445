import React from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'

export type MembershipTileProps = {
  amountApproved: string | undefined
}

export const MembershipTile: React.FC<MembershipTileProps> = (props) => {
  const {amountApproved} = props
  const {t} = useTranslation(['Advance'])

  return (
    <BasicTile>
      <Box gap={'tiny'} direction={'row'} justify={'between'} padding={'small'}>
        <PFText variant={'label_md'}>{t('Advance Amount')}</PFText>
        <PFText variant={'p'}>${amountApproved}</PFText>
      </Box>
    </BasicTile>
  )
}
