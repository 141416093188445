import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type SubscriptionAndAdvanceActivationQueryVariables = Types.Exact<{[key: string]: never}>

export type SubscriptionAndAdvanceActivationQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    bankAccounts: {
      __typename?: 'LinkedAccountCollection'
      all?: Array<{
        __typename: 'LinkedAccount'
        id: string
        mask: string
        name?: string | null
        officialName?: string | null
        status: Types.LinkedAccountStatusCode
        preferredFundingSource?: boolean | null
        achNumbersAvailable?: boolean | null
        isDeletable: boolean
        debitCardNumbersAvailable?: boolean | null
        isLoginRequired: boolean
        type: Types.LinkedAccountType
        lastSyncedAt?: string | null
        institution?: {__typename?: 'Institution'; name: string} | null
      }> | null
    }
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {
        __typename?: 'Loan'
        id: string
        agreement: {
          __typename?: 'LoanAgreement'
          payments: Array<{__typename?: 'LoanAgreementPayment'; amount: string; date: string}>
        }
        aggregateStatus:
          | {__typename?: 'ActiveLoanAggregateStatus'}
          | {__typename?: 'ApprovedLoanAggregateStatus'; amountApproved: string}
          | {__typename?: 'ClosedLoanAggregateStatus'}
          | {__typename?: 'DecisioningLoanAggregateStatus'}
          | {__typename?: 'UndefinedLoanAggregateStatus'}
      } | null
    }
    paymentMethods: {
      __typename?: 'PaymentMethodCollection'
      loanEligible?: Array<
        | {
            __typename: 'AchPaymentMethod'
            id: string
            defaultStatus: Array<Types.DefaultPaymentMethodCode>
            bankAccount: {__typename?: 'BankAccount'; mask: string}
          }
        | {
            __typename: 'CheckPaymentMethod'
            id: string
            defaultStatus: Array<Types.DefaultPaymentMethodCode>
          }
        | {
            __typename: 'DebitCardPaymentMethod'
            id: string
            defaultStatus: Array<Types.DefaultPaymentMethodCode>
            card: {__typename?: 'LinkedDebitCard'; mask?: string | null}
          }
      > | null
    }
  }
}

export type AcceptSubscriptionAgreementMutationMutationVariables = Types.Exact<{
  loanId: Types.Scalars['UUID']['input']
}>

export type AcceptSubscriptionAgreementMutationMutation = {
  __typename?: 'Mutation'
  loanActivationUpdateRequirements: {
    __typename?: 'LoanActivationUpdateRequirementsResponse'
    success: boolean
  }
}

export type AcceptAdvanceAgreementMutationMutationVariables = Types.Exact<{
  loanId: Types.Scalars['UUID']['input']
}>

export type AcceptAdvanceAgreementMutationMutation = {
  __typename?: 'Mutation'
  loanActivationUpdateRequirements: {
    __typename?: 'LoanActivationUpdateRequirementsResponse'
    success: boolean
  }
}

export type AdvanceUpdateRequirementsMutationMutationVariables = Types.Exact<{
  input: Types.LoanActivationRequirementsUpdateInput
}>

export type AdvanceUpdateRequirementsMutationMutation = {
  __typename?: 'Mutation'
  loanActivationUpdateRequirements: {
    __typename?: 'LoanActivationUpdateRequirementsResponse'
    success: boolean
  }
}

export const SubscriptionAndAdvanceActivationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'SubscriptionAndAdvanceActivation'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'bankAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'officialName'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'institution'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'achNumbersAvailable'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'isDeletable'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'debitCardNumbersAvailable'},
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'isLoginRequired'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'lastSyncedAt'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'agreement'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'payments'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'date'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'aggregateStatus'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ApprovedLoanAggregateStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'amountApproved'},
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'paymentMethods'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'loanEligible'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'AchPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'defaultStatus'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'bankAccount'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'defaultStatus'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'card'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'CheckPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'defaultStatus'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscriptionAndAdvanceActivationQuery,
  SubscriptionAndAdvanceActivationQueryVariables
>
export const AcceptSubscriptionAgreementMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'AcceptSubscriptionAgreementMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UUID'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanActivationUpdateRequirements'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'loanId'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'acceptSubscription'},
                      value: {kind: 'BooleanValue', value: true},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptSubscriptionAgreementMutationMutation,
  AcceptSubscriptionAgreementMutationMutationVariables
>
export const AcceptAdvanceAgreementMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'AcceptAdvanceAgreementMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UUID'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanActivationUpdateRequirements'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'loanId'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'acceptAutoPayAgreement'},
                      value: {kind: 'BooleanValue', value: true},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptAdvanceAgreementMutationMutation,
  AcceptAdvanceAgreementMutationMutationVariables
>
export const AdvanceUpdateRequirementsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'AdvanceUpdateRequirementsMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'LoanActivationRequirementsUpdateInput'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanActivationUpdateRequirements'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdvanceUpdateRequirementsMutationMutation,
  AdvanceUpdateRequirementsMutationMutationVariables
>
