import React, {useState} from 'react'

import PFCheckbox from 'src/designSystem/components/atoms/PFCheckbox/PFCheckbox'

type LegalCheckboxProps = React.PropsWithChildren & {
  onPress: (checked: boolean) => void
}

export const LegalCheckbox: React.FC<LegalCheckboxProps> = (props) => {
  const {children, onPress} = props
  const [isChecked, setIsChecked] = useState(false)

  const handlePress = (): void => {
    onPress(!isChecked)
    setIsChecked(!isChecked)
  }

  return (
    <PFCheckbox testID="Legal-Check-Box" checked={isChecked} onPress={handlePress} size="Small">
      {children}
    </PFCheckbox>
  )
}
