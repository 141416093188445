import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type EligibleToApplyForCardQueryQueryVariables = Types.Exact<{[key: string]: never}>

export type EligibleToApplyForCardQueryQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    cardAccounts: {
      __typename?: 'CardAccountCollection'
      active?: {
        __typename?: 'CardAccount'
        id: string
        status:
          | {
              __typename: 'ActiveCardAccountStatus'
              code: Types.CardAccountStatusCode
              subStatus: Types.CardAccountSubStatus
              autopayFrequency: Types.PaymentFrequency
            }
          | {__typename: 'ApprovedCardAccountStatus'; code: Types.CardAccountStatusCode}
          | {__typename: 'CancelledCardAccountStatus'; code: Types.CardAccountStatusCode}
          | {
              __typename: 'DeactivatedCardAccountStatus'
              code: Types.CardAccountStatusCode
              subStatus: Types.CardAccountSubStatus
              autopayFrequency: Types.PaymentFrequency
            }
          | {
              __typename: 'ExpiredCardAccountStatus'
              code: Types.CardAccountStatusCode
              eligibleToReapplyAfter: string
            }
          | {__typename: 'PendingCardAccountStatus'; code: Types.CardAccountStatusCode}
          | {
              __typename: 'RejectedCardAccountStatus'
              code: Types.CardAccountStatusCode
              eligibleToReapplyAfter: string
            }
      } | null
    }
    products: {
      __typename?: 'ProductsCollection'
      eligible: {
        __typename?: 'ProductsOffersCollection'
        all: Array<
          | {
              __typename: 'CardAccountOfferInfo'
              category: Types.ProductCategory
              cardOfferAvailabilityStatus: Types.CardAccountOfferAvailabilityStatus
              offers: Array<{
                __typename: 'CardAccountOffer'
                id: string
                balanceLimit: string
                monthlyMembershipFees: string
                network: Types.CardNetwork
                displayName: string
                preReqs: {
                  __typename: 'ProductPreRequisiteCollection'
                  etag: string
                  requirementMet: Array<Types.ProductPreRequisiteType>
                  requirementNotMet: Array<Types.ProductPreRequisiteType>
                }
              }>
            }
          | {__typename: 'LoanOfferInfo'}
        >
      }
    }
  }
}

export const EligibleToApplyForCardQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'EligibleToApplyForCardQuery'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'cardAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'active'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'status'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ActiveCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'autopayFrequency'},
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ApprovedCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'CancelledCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'DeactivatedCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'autopayFrequency'},
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ExpiredCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'eligibleToReapplyAfter'},
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'PendingCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'RejectedCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'eligibleToReapplyAfter'},
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'products'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'eligible'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'all'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'CardAccountOfferInfo'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'category'}},
                                        {
                                          kind: 'Field',
                                          alias: {
                                            kind: 'Name',
                                            value: 'cardOfferAvailabilityStatus',
                                          },
                                          name: {kind: 'Name', value: 'offerAvailabilityStatus'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'offers'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: '__typename'},
                                              },
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'balanceLimit'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'monthlyMembershipFees',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'network'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'displayName'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'preReqs'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: '__typename'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'etag'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'requirementMet'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'requirementNotMet',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EligibleToApplyForCardQueryQuery,
  EligibleToApplyForCardQueryQueryVariables
>
