import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {View} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {LegalCheckbox} from 'src/designSystem/components/molecules/LegalCheckbox/LegalCheckbox'

export type AdvanceLegalizeProps = {
  handleSetSelected: (value: boolean) => void
  handleMembershipAgreementLink: () => void
  handlePaymentAuthLink: () => void
  defaultPaymentMethodMask: string
}

export const AdvanceLegalizeTile: React.FC<AdvanceLegalizeProps> = (props) => {
  const {
    handleSetSelected: onSetSelected,
    handleMembershipAgreementLink,
    handlePaymentAuthLink,
    defaultPaymentMethodMask,
  } = props

  const [isSelected, setIsSelected] = React.useState(false)
  const {t} = useTranslation('Advance')
  return (
    <View style={{marginTop: 32}}>
      <LegalCheckbox
        onPress={() => {
          setIsSelected(!isSelected)
          onSetSelected(!isSelected)
        }}
      >
        <PFText variant="p">
          <Trans
            i18nKey={'AdvanceLegalize'}
            values={{mask: defaultPaymentMethodMask}}
            t={t}
            components={{
              MembershipAgreementLink: (
                <SvgLink
                  onPress={handleMembershipAgreementLink}
                  linkText={'Membership Agreement'}
                  linkType={'inline'}
                />
              ),
              PaymentAuthLink: (
                <SvgLink
                  onPress={handlePaymentAuthLink}
                  linkText={'Autopay Authorization Agreement'}
                  linkType={'inline'}
                />
              ),
            }}
          />
        </PFText>
      </LegalCheckbox>
    </View>
  )
}
