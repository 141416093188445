import React, {FC, ReactNode} from 'react'
import {View, StyleSheet, StyleProp, ViewStyle, Platform} from 'react-native'

import {
  InfoTileTitle,
  InfoTileTitleProps,
} from 'src/designSystem/components/molecules/InfoTile/InfoTileTitle'
import InfoTileHeader, {
  InfoTileHeaderProps,
} from 'src/designSystem/components/molecules/InfoTile/InfoTileHeader'
import StackedButtons, {
  StackedButtonsAction,
} from 'src/designSystem/components/molecules/StackedButtons/StackedButtons'
import {tileBackground, tileShadowColor, borderColor} from 'src/designSystem/semanticColors'
import {smallGap} from 'src/designSystem/layout'

type InfoTileProps = {
  style?: StyleProp<ViewStyle>
  buttonStyle?: StyleProp<ViewStyle>
  childrenViewStyle?: ViewStyle
  children?: ReactNode
  titleProps?: InfoTileTitleProps
  headerProps?: InfoTileHeaderProps
  primary?: StackedButtonsAction
  secondary?: StackedButtonsAction[]
}

const checkChildren = (children): boolean => {
  if (!children) return false
  if (Array.isArray(children)) {
    return (
      children?.length > 0 &&
      children?.some((value) => {
        return value !== null
      })
    )
  }
  return true
}

const InfoTile: FC<InfoTileProps> = (props) => {
  const {
    style,
    childrenViewStyle,
    children,
    titleProps,
    headerProps,
    primary,
    secondary,
    buttonStyle,
  } = props
  const hasChildren = checkChildren(children)
  const headerTopPadding = titleProps ? smallGap : 0
  const childrenTopPadding = titleProps || headerProps ? smallGap : 0
  const buttonsTopPadding = titleProps || headerProps || hasChildren ? smallGap : 0
  const shadow = Platform.select({
    ios: {
      shadowColor: tileShadowColor,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.1,
      shadowRadius: 10,
    },
    android: {
      elevation: 1,
    },
    web: {
      filter: `drop-shadow(0px 2px 10px ${tileShadowColor})`,
    },
  })

  return (
    <View style={[styles.container, style, shadow]}>
      {titleProps ? <InfoTileTitle {...titleProps} /> : null}
      {headerProps ? (
        <View style={{marginTop: headerTopPadding}}>
          <InfoTileHeader {...headerProps} />
        </View>
      ) : null}
      {hasChildren ? (
        <View style={[childrenViewStyle, {marginTop: childrenTopPadding}]}>{children}</View>
      ) : null}
      {primary || secondary ? (
        <View style={[{marginTop: buttonsTopPadding}, buttonStyle]}>
          <StackedButtons primary={primary} secondary={secondary} />
        </View>
      ) : null}
    </View>
  )
}

export {InfoTile}

const styles = StyleSheet.create({
  container: {
    backgroundColor: tileBackground,
    borderColor: borderColor,
    borderRadius: 4,
    borderWidth: 1,
    elevation: 6,
    marginHorizontal: smallGap,
    padding: smallGap,
  },
})
