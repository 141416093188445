import moment from 'moment'

import {CardAccountStatuses} from 'src/cassandra'

/**
 * A user is eligible to reapply if all conditions are met:
 * * They have a card account status of rejected or expired
 * * The eligibleToReapplyAfter date is before the current date
 */
export const isUserEligibleToReapply = (
  cardAccountStatus: CardAccountStatuses | undefined,
): boolean => {
  let eligibleToReapplyAfter = ''

  if (
    cardAccountStatus?.__typename === 'RejectedCardAccountStatus' ||
    cardAccountStatus?.__typename === 'ExpiredCardAccountStatus'
  ) {
    eligibleToReapplyAfter = cardAccountStatus.eligibleToReapplyAfter
    return moment(eligibleToReapplyAfter).isBefore(moment())
  } else {
    return false
  }
}
