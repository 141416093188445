import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import moment from 'moment'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Separator from 'src/designSystem/components/atoms/Separator/Separator'
import CollapsibleCard from 'src/designSystem/components/organisms/CollapsibleCard/CollapsibleCard'
import {primaryBackgroundColor, separatorColor} from 'src/designSystem/semanticColors'
import {capitalizeFirstLetter} from 'src/lib/user/utils'
import {LoanApprovedPaymentType} from 'src/products/loans/LoanApprovedActivation/LoanPaymentReview/LoanPaymentReviewTemplate/LoanPaymentReviewContent/LoanPaymentReviewContent'

const getPaymentRows = (payments: LoanApprovedPaymentType[]): JSX.Element[] => {
  return payments?.map(
    (payment): JSX.Element => (
      <Box
        key={`payment-${Date.parse((payment.date ?? '').toString())}`}
        gap={'tiny'}
        direction={'row'}
      >
        <Box flex>
          <PFText variant={'p'}>{moment(payment.date).format('MMM DD')}</PFText>
        </Box>
        <PFText variant={'p_semibold'}>${payment.amount ?? '0'}</PFText>
      </Box>
    ),
  )
}

export type PaymentInfoTileProps = {
  payments: LoanApprovedPaymentType[]
  preferredAccountInstitutionName: string
  preferredAccountType: string
  accountMask: string
}

export const PaymentInfoTile: React.FC<PaymentInfoTileProps> = (props) => {
  const {t} = useTranslation(['Advance', 'LoanPaymentReview'])
  const [showSchedule, setShowSchedule] = useState(false)
  const {payments, preferredAccountInstitutionName, preferredAccountType, accountMask} = props

  const paymentRows = getPaymentRows(payments)
  const accountTypeAndMask = `${capitalizeFirstLetter(preferredAccountType)} \u2022\u2022\u2022\u2022${accountMask}`

  return (
    <Box marginBottom={'small'} marginTop={'small'}>
      <CollapsibleCard
        openTitle={t('LoanPaymentReview:ViewSchedule')}
        hideTitle={t('LoanPaymentReview:HideSchedule')}
        open={showSchedule}
        onChangeOpen={(): void => setShowSchedule(!showSchedule)}
        content={
          <Box gap={'medium'}>
            <Separator variant={'solid'} color={separatorColor} />
            {paymentRows}
          </Box>
        }
      >
        <Box padding={'small'}>
          <PFText variant={'label_md'}>{t('AdvancePaymentPlan')}</PFText>
        </Box>
        <Box
          gap={'tiny'}
          paddingVertical={'medium'}
          paddingHorizontal={'small'}
          boxStyle={{backgroundColor: primaryBackgroundColor}}
        >
          <PFText variant={'p_lg_semibold'}>{preferredAccountInstitutionName}</PFText>
          <PFText variant={'p'}>{accountTypeAndMask}</PFText>
        </Box>
      </CollapsibleCard>
    </Box>
  )
}
