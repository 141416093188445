import React, {useEffect, useState} from 'react'
import moment from 'moment'

import {LoanDisbursement} from '@possible/cassandra/src/types/types.mobile.generated'
import {DisbursementSettlementDateType} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAccept.types'
import {getLoanDisbursementAvailableDate} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAccept.utils'
import {MoneyOnTheWayBottomSheetTemplate} from 'src/products/loans/Dashboard/DashboardLoanActive/MoneyOnTheWay/MoneyOnTheWayBottomSheetTemplate'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'

export type MoneyOnTheWayBottomSheetContainerProps = {
  onDismiss: () => void
  disbursement: Pick<LoanDisbursement, 'accountMask' | 'method'>
  loanId: string
  shouldShowMoneyIsOnTheWayMessage: boolean
}

const MoneyOnTheWayBottomSheetContainer: React.FC<MoneyOnTheWayBottomSheetContainerProps> = ({
  onDismiss,
  disbursement,
  loanId,
  shouldShowMoneyIsOnTheWayMessage,
}) => {
  const [disbursementSettlementDate, setDisbursementSettlementDate] =
    useState<DisbursementSettlementDateType>()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (loanId && shouldShowMoneyIsOnTheWayMessage) {
      void getLoanDisbursementAvailableDate({
        loanId: loanId,
        activeDate: moment().utc(),
      }).then((response) => {
        setDisbursementSettlementDate(response)
        setIsVisible(true)
      })
    }
  }, [loanId, shouldShowMoneyIsOnTheWayMessage])

  const disbursementSettlementDateStr = moment
    .utc(disbursementSettlementDate?.adjustedSettlementDatetime)
    .local()
    .format('dddd, MMMM Do')

  const handleOnDismiss = (): void => {
    onDismiss()
    TrackAppEvent(AppEvents.Name.your_money_is_on_the_way_completed, AppEvents.Category.Activation)
    setIsVisible(false)
  }

  usePageViewedAnalytics({
    eventName: AppEvents.Name.your_money_is_on_the_way_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
  })

  return (
    <MoneyOnTheWayBottomSheetTemplate
      isVisible={isVisible}
      onDismiss={handleOnDismiss}
      disbursementMethod={disbursement?.method}
      accountMask={disbursement?.accountMask}
      settlementDateStr={disbursementSettlementDateStr}
    />
  )
}

export {MoneyOnTheWayBottomSheetContainer}
