import React, {FC, ReactNode, useMemo} from 'react'
import {ViewStyle} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import {SvgIconButton} from 'src/designSystem/components/molecules/SvgIconButton/SvgIconButton'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIconNames} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'

export type ActionItemProps = {
  title: string
  text: string | JSX.Element
  handleOnPress?: () => void
  actionIcon?: SvgIconNames
}

export type ActionListProps = {
  items: ActionItemProps[]
  separatorType?: 'none' | 'between' | 'all'
}

/**
Right now this is only used for LoanSubmissionTemplate, however it was built to be the new ActionList to be updated when we have final designs for the new ActionList and then moved into the design system.
 */
const ActionList: FC<ActionListProps> = (props) => {
  const {items, separatorType} = props

  const listItems = useMemo(() => {
    const wrapItemWithKey = (items: ActionItemProps[]): ReactNode[] => {
      return items.map((item, index) => {
        const itemStyle: ViewStyle =
          separatorType === 'all' || separatorType === 'between'
            ? {
                borderBottomColor: NamedColors.ASH,
                borderBottomWidth: 1,
              }
            : {}

        const isLastItem = index === items.length - 1
        const shouldHideLastSeparator = separatorType === 'between' && isLastItem
        const actionIconName = item.actionIcon ?? 'edit'

        return (
          <Box
            paddingHorizontal={'medium'}
            paddingVertical={'small'}
            key={index}
            boxStyle={shouldHideLastSeparator ? {} : itemStyle}
            direction={'row'}
            align={'center'}
            justify={'between'}
          >
            <Box>
              <PFText color={NamedColors.SILVER} variant={'label_sm'}>
                {item.title}
              </PFText>
              {typeof item.text === 'string' ? (
                <PFText variant={'p'}>{item.text}</PFText>
              ) : (
                <>{item.text}</>
              )}
            </Box>
            {item.handleOnPress ? (
              <SvgIconButton
                name={actionIconName}
                colorVariant={'info'}
                onPress={item.handleOnPress}
                accessibilityLabel={
                  typeof item.title === 'string'
                    ? `${actionIconName}-button-${item.title.replace(/\s/g, '-').toLowerCase()}`
                    : `${actionIconName}-button-${index}`
                }
              />
            ) : null}
          </Box>
        )
      })
    }

    return wrapItemWithKey(items)
  }, [items, separatorType])

  return <Box>{listItems}</Box>
}

export {ActionList}
