import {LaunchArgProcessorsType} from 'src/lib/LaunchArgs/LaunchArgs.types'

let DisablePlaidLayer: string | undefined = undefined
const GetDisablePlaidLayer = (): string | undefined => {
  return DisablePlaidLayer
}

const DisablePlaidPlayerProcessor: LaunchArgProcessorsType['disablePlaidLayer'] = (value) => {
  DisablePlaidLayer = value
}

export {GetDisablePlaidLayer, DisablePlaidPlayerProcessor}
