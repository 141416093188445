import {Consumer} from '@possible/cassandra'
import {
  DefaultPaymentMethodCode,
  LoanPaymentMethod,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {transferMethodsType} from 'src/lib/loans/consts'

export type PaymentMethod =
  | {
      __typename: 'AchPaymentMethod'
      id: string
      defaultStatus: Array<DefaultPaymentMethodCode>
      bankAccount: {__typename?: 'BankAccount'; mask: string}
    }
  | {
      __typename: 'CheckPaymentMethod'
      id: string
      defaultStatus: Array<DefaultPaymentMethodCode>
    }
  | {
      __typename: 'DebitCardPaymentMethod'
      id: string
      defaultStatus: Array<DefaultPaymentMethodCode>
      card: {__typename?: 'LinkedDebitCard'; mask?: string | null}
    }

export type LoanDataPaymentMethodCollection = {
  __typename?: 'PaymentMethodCollection'
  loanEligible?: Array<PaymentMethod> | null
}

export const TransferMethodsToLoanPaymentMethod = (
  transferMethod: transferMethodsType,
): LoanPaymentMethod => {
  switch (transferMethod) {
    case 'ach':
      return Consumer.types.LoanPaymentMethod.Ach
    case 'check':
      return Consumer.types.LoanPaymentMethod.Check
    case 'interchange':
      return Consumer.types.LoanPaymentMethod.DebitCard
    case 'none':
      return Consumer.types.LoanPaymentMethod.None
  }
}

export type LoanPaymentMethodInfo = {
  defaultPaymentMethodMask: string
  defaultPaymentMethod?: PaymentMethod
}

export const extractPaymentMethodFromLoanData = (
  paymentMethods: LoanDataPaymentMethodCollection,
): LoanPaymentMethodInfo => {
  const defaultPaymentMethod = paymentMethods.loanEligible?.filter((paymentMethod) =>
    paymentMethod.defaultStatus.includes(DefaultPaymentMethodCode.PaymentDefault),
  )[0]
  let defaultPaymentMethodMask = ''
  switch (defaultPaymentMethod?.__typename) {
    case 'AchPaymentMethod':
      defaultPaymentMethodMask = defaultPaymentMethod.bankAccount.mask
      break
    case 'DebitCardPaymentMethod':
      defaultPaymentMethodMask = defaultPaymentMethod.card.mask ?? ''
      break
    default:
      break
  }

  return {
    defaultPaymentMethod: paymentMethods.loanEligible?.filter((paymentMethod) =>
      paymentMethod.defaultStatus.includes(DefaultPaymentMethodCode.PaymentDefault),
    )[0],
    defaultPaymentMethodMask,
  }
}
