import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

import {CardAccountCollection, CardAccountStatuses} from 'src/cassandra'
import {CardWaitListStatus} from 'src/lib/card/actions'
import {bankLinkStatus} from 'src/lib/user/userEnvConsts'
import {useCanAccessCards} from 'src/products/card//LoanDash/useCanAccessCards'
import {useIsCardEligible} from 'src/products/card/Application/UseIsCardEligible/useIsCardEligible'
import {CardDashTileStatus, getCardOfferStatus} from 'src/products/card/LoanDash/utils'
import {CardLoanDashboardTileQueryDocument} from 'src/products/card/LoanDash/UseCardLoanDashboardTile/CardLoanDashboardTileQuery.gqls'
import {useGetCardWaitlistStatus} from 'src/products/loans/StateSelector/useGetCardWaitlistStatus'
import {allowedStates} from 'src/lib/us_states'

/**
 * Use the CardLoanDashboardTile. Determines if the tile should be shown or not.
 */
export const useCardLoanDashboardTile = (): {
  shouldShowCardLoanDashboardTile: boolean
  cardOfferStatus: CardDashTileStatus
  canAccessCards: boolean
  hadOpportunityToApplyForCard: boolean
  isLoading: boolean
  isLinkedAccountLoading?: boolean
} => {
  const {
    canAccessCards,
    hadOpportunityToApplyForCard,
    isLoading: isLoadingCards,
  } = useCanAccessCards()

  const {data: cardTileData, loading: isCardTileDataLoading} = useCassandraQuery(
    CardLoanDashboardTileQueryDocument,
  )
  const isUserInAllowedUsState = allowedStates().includes(
    cardTileData?.me?.profile?.home?.address?.state ?? '',
  )

  const linkedAndActiveAccounts = cardTileData?.me?.bankAccounts?.all?.filter(
    (account) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      !account.isLoginRequired && account.status?.toLowerCase() === bankLinkStatus.linked,
  )
  const {
    isEligibleToApply,
    isEligibleToReapply,
    isLoading: isLoadingCardEligible,
  } = useIsCardEligible()
  const linkedAndActiveAccountsCount = linkedAndActiveAccounts?.length
  const isUserInGoodStanding = cardTileData?.me?.areLoansInGoodStanding
  const getCardWaitListStatus = useGetCardWaitlistStatus()

  const isWaitListAllowed = !!(
    isUserInAllowedUsState &&
    !!linkedAndActiveAccountsCount &&
    linkedAndActiveAccountsCount > 0 &&
    isUserInGoodStanding &&
    getCardWaitListStatus === CardWaitListStatus.NOT_REGISTERED
  )

  let activeCardStatus: CardAccountStatuses | undefined = undefined
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  const cardAccounts = cardTileData?.me?.cardAccounts as CardAccountCollection
  if (cardAccounts && 'active' in cardAccounts) {
    activeCardStatus = cardAccounts?.active?.status
  }

  const cardOfferStatus: CardDashTileStatus = getCardOfferStatus({
    canAccessCards,
    isWaitListAllowed,
    cardAccountStatus: activeCardStatus,
    isEligibleToReapply,
    isEligibleToApply,
  })
  /* A Card Tile will be displayed
  User has a CardOfferStatuses that is not None or Apply
  or, they qualify for Wait List & have a CardOfferStatuses of Apply
  or, They have the possible_card feature flag enabled
  */
  const shouldShow =
    cardOfferStatus === CardDashTileStatus.Active ||
    cardOfferStatus === CardDashTileStatus.Approved ||
    cardOfferStatus === CardDashTileStatus.Pending ||
    cardOfferStatus === CardDashTileStatus.Apply ||
    cardOfferStatus === CardDashTileStatus.WaitList ||
    cardOfferStatus === CardDashTileStatus.Rejected ||
    cardOfferStatus === CardDashTileStatus.Reapply ||
    cardOfferStatus === CardDashTileStatus.Expired ||
    cardOfferStatus === CardDashTileStatus.Deactivated

  return {
    shouldShowCardLoanDashboardTile: shouldShow,
    cardOfferStatus,
    canAccessCards,
    hadOpportunityToApplyForCard,
    isLoading: isLoadingCards || isCardTileDataLoading || isLoadingCardEligible,
    isLinkedAccountLoading: isCardTileDataLoading,
  }
}
