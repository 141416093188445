import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {CardReapply} from 'src/products/card/CardReapply'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {useIsCardEligible} from 'src/products/card/Application/UseIsCardEligible/useIsCardEligible'
import {CardTileBase} from 'src/products/card/LoanDash/CardTileBase'

export const CardApplicationExpired: FC = () => {
  const {t} = useTranslation('CardApplicationRejected')
  usePageViewedAnalytics({
    eventName: CardEvents.card_expired_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {isLoading, isEligibleToReapply} = useIsCardEligible()

  if (isEligibleToReapply) {
    return <CardReapply tileMarginHorizontal={0} />
  }

  return (
    <BaseTemplate isLoading={isLoading}>
      <CardTileBase
        titleText={t('CardExpiredTitle')}
        content={t('CardExpiredBody')}
        loading={isLoading}
        tileMarginHorizontal={0}
        testID="CardApplicationExpired"
      />
    </BaseTemplate>
  )
}
