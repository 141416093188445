export {LogRocket as SessionReplay} from 'src/lib/sessionReplay/LogRocket.web'

/* This file is not strictly necessary for production code to work correctly but it fixes an issue
 * with asserting against web specific session replay code in the jest test environment.
 *
 * Example:
 *
 * ```tsx
 *  jest.mock<typeof import('src/lib/sessionReplay/sessionReplay')>(
 *    'src/lib/sessionReplay/sessionReplay',
 *    () => jest.requireActual('src/lib/sessionReplay/sessionReplay.web'),
 *  )
 *
 * ```
 */
