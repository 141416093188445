import React, {FC} from 'react'
import {View} from 'react-native'
import {useTranslation} from 'react-i18next'

import {tileSecondaryTitle} from 'src/designSystem/semanticColors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {
  SvgIconButton,
  SvgIconButtonProps,
} from 'src/designSystem/components/molecules/SvgIconButton/SvgIconButton'

export type InfoTileTitleProps = {
  title: string
  secondaryTitle?: string
  isFlagged?: boolean
  svgIconButton?: SvgIconButtonProps
}

const InfoTileTitle: FC<InfoTileTitleProps> = (props: InfoTileTitleProps) => {
  const {title, secondaryTitle, isFlagged, svgIconButton} = props
  const {t} = useTranslation('InfoTile')
  if (!title) return null

  const handleOnPressSvgIconButton = (): void => {
    if (svgIconButton) {
      svgIconButton.onPress()
    }
  }

  return (
    <Box align={'center'} direction={'row'}>
      <View>
        <PFText variant="label_md">{title}</PFText>
      </View>
      {secondaryTitle ? (
        <Box marginLeft={'little'}>
          <PFText variant="label_sm" color={tileSecondaryTitle}>
            {secondaryTitle}
          </PFText>
        </Box>
      ) : null}
      {isFlagged ? (
        <Box paddingLeft={'little'}>
          <PFText variant="label_md" color="warning">
            {t('New')}
          </PFText>
        </Box>
      ) : null}
      {svgIconButton ? (
        <Box marginLeft={'little'}>
          <SvgIconButton
            name={svgIconButton?.name ?? 'info'}
            colorVariant={svgIconButton?.colorVariant ?? 'default'}
            size={'small'}
            isFilled={svgIconButton?.isFilled}
            onPress={handleOnPressSvgIconButton}
          />
        </Box>
      ) : null}
    </Box>
  )
}

export {InfoTileTitle}
