import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {CancelMembershipDocument} from 'src/products/MCU/Profile/Profile.gqls'

type Fn = () => void

type Props = {
  showModal: boolean
  hideModal: Fn
  text: string
  navigation?: StackScreenProps<MainStackParamList>['navigation']
}

const CancelMembershipModal = (props: Props): JSX.Element => {
  const {hideModal, showModal, text, navigation} = props
  const {t} = useTranslation('Profile')
  const [cancelMembership] = useCassandraMutation(CancelMembershipDocument)

  const handlePrimaryAction = async (): Promise<void> => {
    await cancelMembership()
    navigation?.navigate('Dashboard')
  }
  const handleSecondaryAction: Fn = () => hideModal()

  return (
    <OverlaySimple
      title={t('CancelMembershipModalTitle')}
      testID="Cancel-Membership-Modal"
      visible={showModal}
      onOkay={handlePrimaryAction}
      onDismiss={handleSecondaryAction}
      okayButtonText={t('CancelMembershipModalContinue')}
      dismissButtonText={t('CancelMembershipModalKeep')}
      text={text}
    />
  )
}

export {CancelMembershipModal}
