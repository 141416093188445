import React, {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import moment from 'moment'

import {capitalizeFirstLetter} from 'src/lib/user/utils'
import {LoanAgreementPayment} from '@possible/cassandra/src/types/types.mobile.generated'
import {primaryBackgroundColor, separatorColor} from 'src/designSystem/semanticColors'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Box from 'src/designSystem/components/atoms/Box/Box'
import CollapsibleCard from 'src/designSystem/components/organisms/CollapsibleCard/CollapsibleCard'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Separator from 'src/designSystem/components/atoms/Separator/Separator'
import {ExplainHighAprOverlay} from 'src/products/loans/LoanApprovedActivation/LoanPaymentReview/LoanPaymentReviewTemplate/ExplainHighAprOverlay/ExplainHighAprOverlay'

export type LoanApprovedPaymentType = Pick<LoanAgreementPayment, 'amount' | 'date'>

const getPaymentRows = (payments: LoanApprovedPaymentType[]): JSX.Element[] => {
  return payments?.map(
    (payment): JSX.Element => (
      <Box
        key={`payment-${Date.parse((payment.date ?? '').toString())}`}
        gap={'tiny'}
        direction={'row'}
      >
        <Box flex>
          <PFText variant={'p'}>{moment(payment.date).format('MMM DD')}</PFText>
        </Box>
        <PFText variant={'p_semibold'}>${payment.amount ?? '0'}</PFText>
      </Box>
    ),
  )
}

export type LoanPaymentReviewContentProps = {
  accountMask: string
  amountApproved: string
  apr: string
  payments: LoanApprovedPaymentType[]
  preferredAccountInstitutionName: string
  preferredAccountType: string
  totalCost: string
  totalOwed: string
}

const LoanPaymentReviewContent: FC<LoanPaymentReviewContentProps> = (props) => {
  const {
    accountMask,
    amountApproved,
    apr,
    payments,
    preferredAccountInstitutionName,
    preferredAccountType,
    totalCost,
    totalOwed,
  } = props

  const {t} = useTranslation(['LoanPaymentReview', 'Common'])

  const [showSchedule, setShowSchedule] = useState(false)
  const [showExplainHighAprOverlay, setShowExplainHighAprOverlay] = useState(false)

  const paymentRows = getPaymentRows(payments)

  const accountTypeAndMask = `${capitalizeFirstLetter(preferredAccountType)} \u2022\u2022\u2022\u2022${accountMask}`

  const loanFees = (
    <BasicTile>
      <Box gap={'tiny'} padding={'small'}>
        <Box gap={'tiny'} direction={'row'} justify={'between'}>
          <PFText variant={'p_lg'}>{t('TotalFees')}</PFText>
          <PFText variant={'p_lg'}>${totalCost}</PFText>
        </Box>
        <Box gap={'tiny'} direction={'row'} justify={'between'}>
          <PFText variant={'p_lg_semibold'}>{t('RepaymentAmount')}</PFText>
          <PFText variant={'p_lg_semibold'}>${totalOwed}</PFText>
        </Box>
        <Box gap={'tiny'}>
          <PFText variant={'p'}>
            {t('TotalCostTranslatesTo')}{' '}
            <SvgLink
              onPress={(): void => setShowExplainHighAprOverlay(true)}
              linkText={`${apr}% ${t('Apr')}`}
              linkType={'inline'}
            />
            .
          </PFText>
        </Box>
      </Box>
    </BasicTile>
  )

  const paymentPlan = (
    <PFText variant={'label_md'}>
      {payments.length} {t('WeekPaymentPlan')}
    </PFText>
  )

  return (
    <>
      <Box gap={'medium'}>
        <Box align="center" gap={'little'}>
          <PFText variant={'d2'}>${amountApproved}</PFText>
          <PFText variant={'p_semibold'}>{t('AmountApproved')}</PFText>
        </Box>
        {loanFees}
        <Box marginBottom={'small'}>
          <CollapsibleCard
            openTitle={t('ViewSchedule')}
            hideTitle={t('HideSchedule')}
            open={showSchedule}
            onChangeOpen={(): void => setShowSchedule(!showSchedule)}
            content={
              <Box gap={'medium'}>
                <Separator variant={'solid'} color={separatorColor} />
                {paymentRows}
              </Box>
            }
          >
            <Box padding={'small'}>{paymentPlan}</Box>
            <Box
              gap={'tiny'}
              paddingVertical={'medium'}
              paddingHorizontal={'small'}
              boxStyle={{backgroundColor: primaryBackgroundColor}}
            >
              <PFText variant={'p_lg_semibold'}>{preferredAccountInstitutionName}</PFText>
              <PFText variant={'p'}>{accountTypeAndMask}</PFText>
            </Box>
          </CollapsibleCard>
        </Box>
      </Box>

      <ExplainHighAprOverlay showExplainHighAprOverlay={showExplainHighAprOverlay} />
    </>
  )
}

export {LoanPaymentReviewContent}
