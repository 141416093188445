import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Log from 'src/lib/loggingUtil'
import {AdvanceLegalizeTile} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptAdvanceAutoPayAgreements/AdvanceLegalizeTile'
import {
  PaymentInfoTile,
  PaymentInfoTileProps,
} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptAdvanceAutoPayAgreements/PaymentInfoTile'
import {MembershipTile} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptAdvanceAutoPayAgreements/MembershipTile'

export type AcceptAdvanceAutoPayAgreementsTemplateProps = {
  amountApproved: string | undefined
  paymentInfo: PaymentInfoTileProps
  onAcceptAutoPay: () => Promise<void>
}

export const AcceptAdvanceAutoPayAgreementsTemplate: React.FC<
  AcceptAdvanceAutoPayAgreementsTemplateProps
> = (props) => {
  const {onAcceptAutoPay: onAcceptAutoPay} = props
  const {t} = useTranslation('Advance')
  const [isBusy, setIsBusy] = React.useState(false)
  const [isSelected, setIsSelected] = React.useState(false)

  const {amountApproved, paymentInfo} = props

  const handleContinue = useCallback(async () => {
    try {
      setIsBusy(true)
      await onAcceptAutoPay()
    } catch (e) {
      Log.error(e, 'AcceptAdvanceAutoPayAgreementsTemplate exception : ')
    } finally {
      setIsBusy(false)
    }
  }, [onAcceptAutoPay])

  const buttonLockupProps: ButtonLockupProps = {
    type: 'singleButton',
    primary: {
      text: 'Continue',
      onPress: handleContinue,
      disabled: !isSelected || isBusy,
    },
  }

  return (
    <Page
      title={t('Get your advance')}
      titleTextAlignment="left"
      description={t('Reschedule payment dates and pay in four installments for free.')}
      buttonProps={buttonLockupProps}
      variant={'generic'}
      smallTopGap
    >
      <MembershipTile amountApproved={amountApproved} />
      <PaymentInfoTile {...paymentInfo} />
      <AdvanceLegalizeTile
        handleSetSelected={setIsSelected}
        handleMembershipAgreementLink={() => {}}
        handlePaymentAuthLink={() => {}}
        defaultPaymentMethodMask={paymentInfo?.accountMask}
      />
    </Page>
  )
}
