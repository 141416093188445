import React, {useEffect} from 'react'

import {accountAndRoutingProvidedSelector} from 'src/lib/loans/selector'
import {useAcceptSubscriptionAndAdvanceQuery} from 'src/products/loans/LoanApprovedActivation/advanceUtils/advanceGqlUtils'
import {useAcceptLoan} from 'src/products/loans/LoanApprovedActivation/useAcceptLoan/useAcceptLoan'
import {usePfSelector} from 'src/store/utils'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

//This page will submit the advance, once it is rendered.
//This is a bit of a hack to work with the current Loan Activation Workflow.
//The UX design has the Advance get activated on disbursement selection. However
//disbursement selection can be multiple steps. So we need a way to know when it's complete
//and then submit the advance.
export const ActivateAdvanceContainer: React.FC<WorkflowPreReqFulfillScreenProps> = (props) => {
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const query = useAcceptSubscriptionAndAdvanceQuery()
  const accountAndRouting = usePfSelector(accountAndRoutingProvidedSelector)
  const [acceptLoan] = useAcceptLoan()

  const {onPreReqFulfilled} = props

  useEffect(() => {
    const loanId = query?.loanId
    if (isSubmitted || !loanId || !acceptLoan) {
      return
    }

    setIsSubmitted(true)

    const submitAdvance = async (): Promise<void> => {
      const isSuccessful = await acceptLoan({
        loanId,
        accountNumber: accountAndRouting?.account_number,
        routingNumber: accountAndRouting?.routing_number,
      })

      if (isSuccessful) {
        await onPreReqFulfilled()
      }
    }

    void submitAdvance()
  }, [isSubmitted, query, acceptLoan, onPreReqFulfilled, accountAndRouting])
  return null
}
