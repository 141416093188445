import {
  CardAccountStatusCode,
  DeactivatedCardAccountReasonCode,
  CardAccountStatuses,
  Maybe,
} from 'src/cassandra'

export enum CardDashTileStatus {
  None,
  Apply,
  Pending,
  Approved,
  Rejected,
  Active,
  WaitList,
  Reapply,
  Expired,
  Deactivated,
}

export const isCardAccountFraudulent = (
  cardAccountStatus?: CardAccountStatuses | null,
): boolean => {
  return !!(
    cardAccountStatus?.code === CardAccountStatusCode.Deactivated &&
    cardAccountStatus?.__typename === 'DeactivatedCardAccountStatus' &&
    cardAccountStatus?.reasonCode === DeactivatedCardAccountReasonCode.Fraud
  )
}

export const isUserRejectedDueToQuota = (cardAccountStatus?: CardAccountStatuses): boolean => {
  if (
    cardAccountStatus?.__typename === 'RejectedCardAccountStatus' &&
    cardAccountStatus.rejectionReasonCodes?.length
  ) {
    const rejectedForQuota: Maybe<string>[] = (cardAccountStatus.rejectionReasonCodes ?? []).filter(
      (code) => code === 'user.reject.quota_reached',
    )
    return rejectedForQuota.length > 0
  }
  return false
}

type GetCardOfferStatusType = {
  canAccessCards: boolean
  isWaitListAllowed: boolean
  cardAccountStatus?: CardAccountStatuses
  isEligibleToReapply: boolean
  isEligibleToApply: boolean
}

// eslint-disable-next-line complexity
export const getCardOfferStatus = ({
  canAccessCards,
  isWaitListAllowed,
  cardAccountStatus,
  isEligibleToReapply,
  isEligibleToApply,
}: GetCardOfferStatusType): CardDashTileStatus => {
  let tile: CardDashTileStatus
  switch (true) {
    case cardAccountStatus?.code === CardAccountStatusCode.Active:
      tile = CardDashTileStatus.Active
      break
    case cardAccountStatus?.code === CardAccountStatusCode.Approved:
      tile = CardDashTileStatus.Approved
      break
    case cardAccountStatus?.code === CardAccountStatusCode.Pending:
      tile = CardDashTileStatus.Pending
      break
    case cardAccountStatus?.code === CardAccountStatusCode.Rejected:
      if (isEligibleToReapply) {
        tile = CardDashTileStatus.Reapply
      } else {
        tile = CardDashTileStatus.Rejected
      }
      break
    case cardAccountStatus?.code === CardAccountStatusCode.Expired:
      if (isEligibleToReapply) {
        tile = CardDashTileStatus.Reapply
      } else {
        tile = CardDashTileStatus.Expired
      }
      break
    case cardAccountStatus?.code === CardAccountStatusCode.Deactivated:
      if (isCardAccountFraudulent(cardAccountStatus)) {
        tile = CardDashTileStatus.None
      } else {
        tile = CardDashTileStatus.Deactivated
      }
      break
    case cardAccountStatus?.code === CardAccountStatusCode.Cancelled:
    case !cardAccountStatus:
      if (isEligibleToApply) {
        tile = CardDashTileStatus.Apply
      } else {
        tile = CardDashTileStatus.None
      }
      break
    default:
      tile = CardDashTileStatus.None
      break
  }

  if (hasPermissionToViewTile(canAccessCards, tile)) {
    return tile
  }

  if (tile === CardDashTileStatus.Apply && isWaitListAllowed) {
    return CardDashTileStatus.WaitList
  }

  return CardDashTileStatus.None
}

const hasPermissionToViewTile = (canAccessCards: boolean, tile: CardDashTileStatus): boolean => {
  // Card access required for all tiles except waitlist, rejection, expiration, deactivation and reapply.
  return (
    canAccessCards ||
    [
      CardDashTileStatus.WaitList,
      CardDashTileStatus.Rejected,
      CardDashTileStatus.Expired,
      CardDashTileStatus.Reapply,
      CardDashTileStatus.Deactivated,
    ].includes(tile)
  )
}

export enum TilesToDisplayOnce {
  CardRejectedTile = 'hasDisplayedCardRejectedTile',
  CardExpiredTile = 'hasDisplayedCardExpiredTile',
}
