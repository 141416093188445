import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {BottomSheet} from 'src/designSystem/components/atoms/BottomSheet/BottomSheet'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {LoanPaymentMethod} from '@possible/cassandra/src/types/types.mobile.generated'

export type MoneyOnTheWayBottomSheetTemplateProps = {
  isVisible: boolean
  onDismiss: () => void
  disbursementMethod: LoanPaymentMethod
  accountMask: string
  settlementDateStr: string
}

const MoneyOnTheWayBottomSheetTemplate: React.FC<MoneyOnTheWayBottomSheetTemplateProps> = ({
  isVisible,
  onDismiss: handleOnDismiss,
  disbursementMethod,
  accountMask,
  settlementDateStr,
}) => {
  const {t} = useTranslation(['DashboardLoanActive', 'LoanApproved'])

  const renderDisbursementDescription = (): React.ReactNode => {
    if (disbursementMethod === LoanPaymentMethod.DebitCard) {
      return <PFText variant={'p'}>{t('MoneyOnTheWayInterchangeDate')}</PFText>
    }

    if (disbursementMethod === LoanPaymentMethod.Rtp) {
      return (
        <PFText variant={'p'}>
          <Trans i18nKey={'MoneyOnTheWayRtpDate'} values={{mask: accountMask}}>
            Check your account ending in {''}
            <PFText variant={'p_semibold'}>{accountMask}</PFText>
            {''} for your Possible funds. Funds usually arrive within 10 minutes.
          </Trans>
        </PFText>
      )
    }

    return (
      <PFText variant={'p'}>
        <Trans i18nKey={'MoneyOnTheWayDate'} values={{settlementDateStr}}>
          It should show up in your account by the end of the day on {''}
          <PFText variant={'p_semibold'}>{settlementDateStr}</PFText>
        </Trans>
      </PFText>
    )
  }

  return (
    <BottomSheet
      visible={isVisible}
      onDismiss={handleOnDismiss}
      showDots={false}
      dismissButton={{text: t('LoanApproved:ContinueToDashboard')}}
      fullWidthTitle={false}
      testID="Money-On-The-Way-Bottom-Sheet"
    >
      <Box gap={'small'} marginBottom={'small'}>
        <SvgIcon name={'checkWithCircle'} colorVariant={'success'} size={'enormous'} />
        <PFText variant="h3">{t('MoneyOnTheWay')}</PFText>
        {renderDisbursementDescription()}
      </Box>
    </BottomSheet>
  )
}

export {MoneyOnTheWayBottomSheetTemplate}
