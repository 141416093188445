import {CardAccountStatuses} from '@possible/cassandra/src/types/types.mobile.generated'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {EligibleToApplyForCardQueryDocument} from 'src/products/card/Application/UseIsCardEligible/EligibleToApplyForCardQuery.gqls'
import {isUserEligibleToReapply} from 'src/products/card/Application/UseIsCardEligible/IsCardEligible.utils'
import {getCardOfferInfo} from 'src/products/general/ProductHub/ProductHub.utils'

type IsCardEligibleType = {
  isLoading: boolean
  isEligibleForCards: boolean
  isEligibleToApply: boolean
  isEligibleToReapply: boolean
}

export const useIsCardEligible = (): IsCardEligibleType => {
  const {data, loading: isLoading} = useCassandraQuery(EligibleToApplyForCardQueryDocument, {
    fetchPolicy: 'cache-first',
  })

  // eslint-disable-next-line no-type-assertion/no-type-assertion
  const activeCardStatus = data?.me.cardAccounts.active?.status as CardAccountStatuses | undefined
  const hasCardOffers = !!getCardOfferInfo(data?.me?.products?.eligible.all ?? [])

  /**
   * User is eligible to be added to cards if:
   * They don't have a cards account (new cards user)
   * or
   * they are a rejected user who is eligible to reapply (this would be for crut users who were removed from cards after being rejected)
   */
  const isEligibleForCards = !activeCardStatus || isUserEligibleToReapply(activeCardStatus)

  /**
   * A user is eligible to apply if:
   * * They have not already applied
   * * They have a card offer
   */
  const isEligibleToApply = !activeCardStatus && hasCardOffers

  /**
   * A user is eligible to reapply if:
   * * They have a card offer
   * * They have a card account status of rejected or expired
   * * The eligibleToReapplyAfter date is before the current date
   */
  const isEligibleToReapply = hasCardOffers && isUserEligibleToReapply(activeCardStatus)

  return {
    isLoading,
    isEligibleForCards,
    isEligibleToApply,
    isEligibleToReapply,
  }
}
