import {PreReqSortFunctionSelectorType} from 'src/workflows/experimentation'
import {BaselinePreReqSortFunction} from 'src/workflows/order/baselinePreReqOrder'
import {OnboardPreReqSortFunction} from 'src/workflows/order/onboardRevampPreReqOrder'

type SortSelectorPassThrough = {
  isLoanOnboarding: boolean
}

export const sortSelector: PreReqSortFunctionSelectorType<SortSelectorPassThrough> = (
  _selectedOffer,
  _unmetPreReqs,
  passthrough,
) => {
  const {isLoanOnboarding} = passthrough
  let preReqSortFunction = BaselinePreReqSortFunction

  // Update here as new experiments are added
  if (isLoanOnboarding) {
    preReqSortFunction = OnboardPreReqSortFunction
  }

  return preReqSortFunction
}
