import React, {useCallback} from 'react'

import {AcceptSubscriptionAgreementTemplate} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptSubscriptionAgreement/AcceptSubscriptionAgreementTemplate'
import {
  useAcceptSubscriptionAndAdvanceQuery,
  useAcceptSubscriptionMutation,
} from 'src/products/loans/LoanApprovedActivation/advanceUtils/advanceGqlUtils'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

const AcceptSubscriptionAgreementWorkflowGQLContainer: React.FC<
  WorkflowPreReqFulfillScreenProps
> = (props) => {
  const {onPreReqFulfilled} = props
  const query = useAcceptSubscriptionAndAdvanceQuery()
  const mutate = useAcceptSubscriptionMutation(query?.loanId)

  const handleComplete = useCallback(async () => {
    const isSuccess = await mutate()
    if (!isSuccess) return

    await onPreReqFulfilled()
  }, [mutate, onPreReqFulfilled])
  return (
    <AcceptSubscriptionAgreementTemplate
      amountApproved={query?.amountApproved}
      onPreReqFulfilled={handleComplete}
    />
  )
}

export {AcceptSubscriptionAgreementWorkflowGQLContainer}
