import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {View} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {LegalCheckbox} from 'src/designSystem/components/molecules/LegalCheckbox/LegalCheckbox'

export type SubscriptionLegalizeProps = {
  onSetSelected: (value: boolean) => void
  onMembershipAgreementLink: () => void
  onPaymentAuthLink: () => void
}

export const SubscriptionLegalize: React.FC<SubscriptionLegalizeProps> = (props) => {
  const {
    onSetSelected: handleSetSelected,
    onMembershipAgreementLink: handleMembershipAgreementLink,
    onPaymentAuthLink: handlePaymentAuthLink,
  } = props
  const {t} = useTranslation('Advance')
  return (
    <View style={{marginTop: 32}}>
      <LegalCheckbox onPress={handleSetSelected}>
        <PFText variant="p">
          <Trans
            i18nKey={'AgreementText'}
            t={t}
            components={{
              MembershipAgreementLink: (
                <SvgLink
                  onPress={handleMembershipAgreementLink}
                  linkText={'Membership Agreement'}
                  linkType={'inline'}
                />
              ),
              PaymentAuthLink: (
                <SvgLink
                  onPress={handlePaymentAuthLink}
                  linkText={'Autopay Authorization Agreement'}
                  linkType={'inline'}
                />
              ),
            }}
          />
        </PFText>
      </LegalCheckbox>
    </View>
  )
}
