import {LaunchArgProcessorsType, PossibleLaunchArgsType} from 'src/lib/LaunchArgs/LaunchArgs.types'

let isSessionReplayEnabled: PossibleLaunchArgsType['enableSessionReplay'] = true

const getIsSessionReplayEnabled = (): PossibleLaunchArgsType['enableSessionReplay'] => {
  return isSessionReplayEnabled
}

const setIsSessionReplayEnabled: LaunchArgProcessorsType['enableSessionReplay'] = (value) => {
  isSessionReplayEnabled = value
}

export {getIsSessionReplayEnabled, setIsSessionReplayEnabled}
