import React, {useMemo} from 'react'
import {TFunction} from 'i18next'

import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Coin from 'src/assets/icons/Coin.svg'
import Check from 'src/assets/icons/Check1.svg'
import {ProgressTrackerStepProps} from 'src/designSystem/components/molecules/ProgressTracker/ProgressTrackerStep'

const useSteps = (t: TFunction<'Advance', undefined>): ProgressTrackerStepProps[] => {
  return useMemo(() => {
    return [
      {
        key: 'MembershipMonthly',
        isComplete: true,
        label: <PFSvgContain svg={Coin} width={20} />,
        shouldAlignStepItemsCenter: true,
        title: <PFText variant="p">{t('MembershipMonthly')}</PFText>,
      },
      {
        key: 'MembershipFee',
        isComplete: true,
        label: <PFSvgContain svg={Check} width={20} />,
        shouldAlignStepItemsCenter: true,
        title: <PFText variant="p">{t('MembershipFlatFee')}</PFText>,
      },
    ]
  }, [t])
}

export {useSteps}
