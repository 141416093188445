import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
  ServerError,
  ServerParseError,
} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import {ErrorLink, onError} from '@apollo/client/link/error'
import fetch from 'cross-fetch'
import {DocumentNode, GraphQLFormattedError} from 'graphql'
import {useEffect, useState} from 'react'

import {integrateChaosModeForApolloHttpLink} from '@possible/chaos'
import {GraphqlURIMap} from './env/ApiUris'
import {ApolloClientType, Applications, EnvironmentType} from './types'
import {CassandraClientCache} from './CassandraClientCache'

export type LogCallback = {
  log: (...args) => void
  warn: (...args) => void
  error: (...args) => void
}

/**
 * Operations to run against birdsong instead of the normal environment GQL server.
 * Use the operation name, not the query/mutation name.
 * @example (operation name) 'GetOnboardingCurrentModule' NOT (query/mutation name) 'getOnboardingCurrentModule'.
 * @ THIS ARRAY SHOULD BE EMPTY ON MERGE.
 */
const birdsongOperations = [
  // Uncomment the following to use birdsong for MPO requests:
  // 'GetOnboardingCurrentModule',
  // 'OnboardingMoveToNextModule',
  // 'OnboardingMoveToPreviousModule',
]

const cassandraClientCache = new CassandraClientCache()

let application: Applications | undefined = undefined
let environment: EnvironmentType | undefined = undefined
let logging: LogCallback | undefined = undefined

function logGraphQLErrors(graphQLErrors: readonly GraphQLFormattedError[], operationName: string) {
  const logger = GetLogging()?.warn
  graphQLErrors.forEach((error) => {
    const {message, locations, path} = error
    logger?.(
      `[Cassandra -- ${operationName}]: Message: "${message}"; Path: "${path}"; Locations: [${locations?.map(
        (l) => `Line: ${l.line}, column: ${l.column}, `,
      )}]`,
    )
  })
}

const logNetworkError = (
  networkError: Error | ServerError | ServerParseError,
  operationName: string,
) => {
  // We get here and will get here repeatly if the device loses internet connection.
  // We do not need to log this connection issue
  const noInternetConnection =
    networkError.name === 'TypeError' && networkError.message === 'Network request failed'
  if (!noInternetConnection) {
    const serverError = networkError as ServerError
    // We do not treat 403 Forbidden as an error because it happens in our
    // normal operation when a token is expired
    const is403 = serverError.statusCode === 403
    const log = is403 ? GetLogging()?.log : GetLogging()?.error
    log?.(`[Cassandra -- (${operationName}) Network Error]: ${networkError}`)
  }
}

export const defaultApolloClientOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
}

const fetchMethod: (input: RequestInfo | URL, init?: RequestInit) => Promise<Response> = async (
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<Response> => {
  if (GetEnvironment() !== EnvironmentType.Prod && GetApplication() === Applications.MOBILE) {
    // when in preprod we integrate Chaos Mode (the @possible/chaos package) into the link chain so that
    // it can intercept and override http requests/responses if Chaos Mode is configured to simulate
    // failure for this request
    return integrateChaosModeForApolloHttpLink(fetch, input, init)
  } else {
    return fetch(input, init)
  }
}

/**
 * The Apollo clients that are generated by GenerateApolloClients.
 */
type GeneratedApolloClients<
  CreatePublic extends boolean | undefined,
  CreatePublicLocal extends boolean | undefined,
  CreatePrivate extends boolean | undefined,
  CreatePrivateLocal extends boolean | undefined,
> = {
  // each client is optional based on the arguments passed.
  // i.e. privateClient only exists if CreatePrivate == true
  privateClient: CreatePrivate extends true ? ApolloClientType : undefined
  privateLocalClient: CreatePrivateLocal extends true ? ApolloClientType : undefined
  publicClient: CreatePublic extends true ? ApolloClientType : undefined
  publicLocalClient: CreatePublicLocal extends true ? ApolloClientType : undefined
}
/**
 * Generate Apollo clients for the given application and environment.
 * Does not check for existing clients and will always create all variants.
 * You probably want to use CreateApolloClient which does caching to avoid creating
 * excessive or unnecessary clients.
 */
export const GenerateApolloClients = <
  CreatePublic extends boolean | undefined,
  CreatePublicLocal extends boolean | undefined,
  CreatePrivate extends boolean | undefined,
  CreatePrivateLocal extends boolean | undefined,
>({
  app,
  env,
  token,
  createPublic = false,
  createPublicLocal = false,
  createPrivate = false,
  createPrivateLocal = false,
  onError: handleOnError,
  onSuccess: handleOnSuccess,
}: {
  app: Applications
  env: EnvironmentType
  createPublic?: CreatePublic
  createPublicLocal?: CreatePublicLocal
  createPrivate?: CreatePrivate
  createPrivateLocal?: CreatePrivateLocal
  token?: string
  logger?: LogCallback
  setGlobalClient?: boolean
  onError?: ErrorLink.ErrorHandler
  onSuccess?: () => void
}): GeneratedApolloClients<CreatePublic, CreatePublicLocal, CreatePrivate, CreatePrivateLocal> => {
  if (env === EnvironmentType.NotSet) {
    throw new Error(
      'cassandra GenerateApolloClients: Cannot create apollo client with environment type NotSet',
    )
  }
  if (!GraphqlURIMap[app][env]) {
    throw new Error(
      `cassandra GenerateApolloClients: No graphql URI found for app: ${app} and env: ${env}`,
    )
  }

  const httpLink = new HttpLink({
    uri: GraphqlURIMap[app][env].graphqlUri,
    fetch: fetchMethod,
  })

  const onSuccessLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      handleOnSuccess?.()
      return data
    })
  })

  const localLink = new HttpLink({
    uri: GraphqlURIMap[app][EnvironmentType.Local].graphqlUri,
    fetch: fetchMethod,
  })

  const publicHttpLink = new HttpLink({
    uri: GraphqlURIMap[app][env].graphqlPublicUri,
    fetch: fetchMethod,
  })

  const publicLocalLink = new HttpLink({
    uri: GraphqlURIMap[app][EnvironmentType.Local].graphqlPublicUri,
    fetch: fetchMethod,
  })

  const errorLink = onError((errorHandler) => {
    const {graphQLErrors, networkError, operation} = errorHandler
    handleOnError?.(errorHandler)
    const operationName = operation.operationName ?? '<Unknown Operation>'

    if (graphQLErrors) {
      logGraphQLErrors(graphQLErrors, operationName)
    }

    if (networkError) {
      logNetworkError(networkError, operationName)
    }
  })

  const authLink = token
    ? setContext((_, {headers}) => {
        return {
          // headers is not typed... do they really exist?
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          headers: {
            ...headers,
            authorization: `Bearer ${token}`,
          },
        }
      })
    : undefined

  const cache = new InMemoryCache({
    typePolicies: {
      InstallmentPayment: {
        keyFields: ['id', 'ordinal', 'executeAt'],
      },
      AutomaticPaymentSchedule: {
        keyFields: ['id', 'paymentDate'],
      },
    },
    possibleTypes: {
      CardAccountStatuses: [
        'ActiveCardAccountStatus',
        'PendingCardAccountStatus',
        'RejectedCardAccountStatus',
        'ApprovedCardAccountStatus',
        'CancelledCardAccountStatus',
        'ExpiredCardAccountStatus',
        'DeactivatedCardAccountStatus',
      ],
    },
  })

  const links: ApolloLink[] = []
  if (authLink) {
    links.push(authLink)
  }
  links.push(errorLink)
  links.push(onSuccessLink)
  links.push(httpLink)

  let privateClient: ApolloClientType | undefined = undefined
  let privateLocalClient: ApolloClientType | undefined = undefined
  let publicClient: ApolloClientType | undefined = undefined
  let publicLocalClient: ApolloClientType | undefined = undefined

  if (createPrivate) {
    privateClient = new ApolloClient({
      defaultOptions: defaultApolloClientOptions,
      cache,
      link: ApolloLink.from(links),
      // Adds support for Apollo Client Dev Tools
      devtools: {
        enabled: env !== EnvironmentType.Prod,
        name: 'Private',
      },
    })
  }

  if (createPublic) {
    publicClient = new ApolloClient({
      defaultOptions: defaultApolloClientOptions,
      cache,
      link: ApolloLink.from([errorLink, publicHttpLink]),
      devtools: {
        enabled: env !== EnvironmentType.Prod,
        name: 'Public',
      },
    })
  }

  // these localClients can be used for testing agains a local client (birdsong)
  if (createPublicLocal) {
    publicLocalClient = new ApolloClient({
      defaultOptions: defaultApolloClientOptions,
      cache,
      link: ApolloLink.from([errorLink, publicLocalLink]),
      devtools: {
        enabled: env !== EnvironmentType.Prod,
        name: 'Birdsong: Public',
      },
    })
  }

  if (createPrivateLocal) {
    privateLocalClient = new ApolloClient({
      defaultOptions: defaultApolloClientOptions,
      cache,
      link: authLink
        ? ApolloLink.from([authLink, errorLink, localLink])
        : ApolloLink.from([errorLink, localLink]),
      devtools: {
        enabled: env !== EnvironmentType.Prod,
        name: 'Birdsong: Private',
      },
    })
  }
  // return type is correctly dependent on arguments but TS can't infer it
  // correctly so we have to cast it
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  return {
    privateClient: createPrivate === true ? privateClient : undefined,
    privateLocalClient: createPrivateLocal ? privateLocalClient : undefined,
    publicClient: createPublic ? publicClient : undefined,
    publicLocalClient: createPublicLocal ? publicLocalClient : undefined,
  } as GeneratedApolloClients<CreatePublic, CreatePublicLocal, CreatePrivate, CreatePrivateLocal>
}

/**
 * This must be called before any graphql queries or mutations are made.
 * Do not cache the returned object. Use GetClient to get the current client instance.
 * @param app The intended use of the application. Mobile (Consumer) or IAM (Administration)
 * @param env The environment to use. Local/Dev/Staging/Prod
 * @param token The authorization token with which to authenticate
 * @returns A client that can be used to make queries and mutations. Do not cache.
 */
export const CreateApolloClient = ({
  app,
  env,
  token,
  logger,
  setGlobalClient = true,
  onError: handleOnError,
  onSuccess: handleOnSuccess,
}: {
  app: Applications
  env: EnvironmentType
  token?: string
  logger?: LogCallback
  setGlobalClient?: boolean
  onError?: ErrorLink.ErrorHandler
  onSuccess?: () => void
}): {
  client: ApolloClientType | undefined
  publicClient: ApolloClientType
} => {
  logging = logger

  const initialClientsFromCache = cassandraClientCache.getClients({app})
  let publicClient: ApolloClientType | undefined = initialClientsFromCache.publicClient
  let publicLocalClient: ApolloClientType | undefined = initialClientsFromCache.publicLocalClient
  let privateClient: ApolloClientType | undefined = initialClientsFromCache.privateClient
  let privateLocalClient: ApolloClientType | undefined = initialClientsFromCache.privateLocalClient

  let shouldCreatePublic = false
  let shouldCreatePublicLocal = false
  let shouldCreatePrivate = false
  let shouldCreatePrivateLocal = false

  // only create private client if we have an auth token and it hasn't already been created
  if (!initialClientsFromCache.privateClient && token) {
    shouldCreatePrivate = true
  }
  // only create public client once since it needs no token
  if (!initialClientsFromCache.publicClient) {
    shouldCreatePublic = true
  }

  /* These clients can be used for testing against a local client (birdsong).
   * We only create them if we have operations configured to use birdsong and
   * they haven't already been created */
  if (
    birdsongOperations.length &&
    (!initialClientsFromCache.publicLocalClient || !initialClientsFromCache.privateLocalClient)
  ) {
    shouldCreatePublicLocal = true
    shouldCreatePrivateLocal = true
  }
  const newlyGeneratedClients = GenerateApolloClients({
    createPublic: shouldCreatePublic,
    createPublicLocal: shouldCreatePublicLocal,
    createPrivate: shouldCreatePrivate,
    createPrivateLocal: shouldCreatePrivateLocal,
    app,
    env,
    token,
    onError: handleOnError,
    onSuccess: handleOnSuccess,
  })
  publicClient = publicClient ?? newlyGeneratedClients.publicClient
  publicLocalClient = publicLocalClient ?? newlyGeneratedClients.publicLocalClient
  privateClient = privateClient ?? newlyGeneratedClients.privateClient
  privateLocalClient = privateLocalClient ?? newlyGeneratedClients.privateLocalClient

  if (setGlobalClient) {
    SetClient(privateClient)
    SetLocalClient(privateLocalClient)
  }
  cassandraClientCache.setClients({
    applicationName: app,
    publicClient,
    privateClient,
    publicLocalClient,
    privateLocalClient,
  })

  application = app
  environment = env

  if (!publicClient) {
    // this shouldn't happen but it makes TS happy
    throw new Error('No Apollo public client has been created')
  }

  return {client: privateClient, publicClient}
}

export const DestroyClient = (): void => {
  const {privateClient, privateLocalClient} = cassandraClientCache.getClients()
  if (privateClient) {
    privateClient.stop()
    SetClient(undefined)
  }

  if (privateLocalClient) {
    privateLocalClient.stop()
  }
  cassandraClientCache.setClients({privateClient: undefined, privateLocalClient: undefined})
}

/**
 * Get the current apollo client used to make queries and mutations.
 * @param forDocument Pass a DocumentNode here if you would like to configure it to
 * be run locally -- against birdsong instead of the normal graphql environment.
 * See birdsongOperations.
 * @returns The current apollo client. Do not cache.
 */
export const GetClient = (forDocument?: DocumentNode): ApolloClientType => {
  const {privateClient} = cassandraClientCache.getClients()

  if (!privateClient) {
    throw new Error('No Apollo client has been created!')
  }

  if (birdsongOperations.length > 0 && forDocument) {
    if (forDocument.definitions[0].kind === 'OperationDefinition') {
      if (forDocument.definitions[0].name?.kind === 'Name') {
        const name = forDocument.definitions[0].name?.value
        // @ts-ignore
        if (name && birdsongOperations.includes(name)) {
          console.log(`Using birdsong for operation: ${name}`)
          return GetLocalClient()
        }
      }
    }
  }

  return privateClient
}

export const GetPublicClient = (forDocument?: DocumentNode): ApolloClientType => {
  const {publicClient} = cassandraClientCache.getClients()
  if (!publicClient) {
    throw new Error('No Apollo client has been created!')
  }

  if (birdsongOperations.length > 0 && forDocument) {
    if (forDocument.definitions[0].kind === 'OperationDefinition') {
      if (forDocument.definitions[0].name?.kind === 'Name') {
        const name = forDocument.definitions[0].name?.value
        // @ts-ignore
        if (name && birdsongOperations.includes(name)) {
          console.log(`Using birdsong for operation: ${name}`)
          return GetPublicLocalClient()
        }
      }
    }
  }

  return publicClient
}

const GetLocalClient = (): ApolloClientType => {
  const {privateLocalClient} = cassandraClientCache.getClients()
  if (!privateLocalClient) {
    throw new Error('No Local client has been created!')
  }

  return privateLocalClient
}

const GetPublicLocalClient = (): ApolloClientType => {
  const {publicLocalClient} = cassandraClientCache.getClients()
  if (!publicLocalClient) {
    throw new Error('No Local client has been created!')
  }

  return publicLocalClient
}

export const GetApplication = (): Applications | undefined => {
  return application
}

export const GetEnvironment = (): EnvironmentType | undefined => {
  return environment
}

export const GetLogging = (): LogCallback | undefined => {
  return logging
}

let observers: React.Dispatch<React.SetStateAction<ApolloClientType | undefined>>[] = []

export const SetClient = (newClient: ApolloClientType | undefined) => {
  const {privateClient} = cassandraClientCache.getClients()
  if (privateClient && GetApplication() === Applications.MOBILE) {
    privateClient.stop()
    void privateClient.resetStore()
  }

  cassandraClientCache.setClients({privateClient: newClient})
  observers.forEach((update) => update(newClient))
}

export const SetPublicClient = (newClient: ApolloClientType | undefined) => {
  cassandraClientCache.setClients({publicClient: newClient})
}

export const SetLocalClient = (newClient: ApolloClientType | undefined) => {
  cassandraClientCache.setClients({privateLocalClient: newClient})
}

const SubscribeToClient = (subscriber) => {
  observers.push(subscriber)
}

const UnsubscribeToClient = (unsubscriber) => {
  observers = observers.filter((observer) => observer !== unsubscriber)
}

export type CassandraClientOptions = {
  isPublic?: boolean
  withBirdsong?: boolean
}

export const useCassandraClient = (
  options: CassandraClientOptions = {},
): [ApolloClientType | undefined, (newClient: ApolloClientType | undefined) => void] => {
  const {isPublic = false, withBirdsong = false} = options
  const {privateClient, publicClient, privateLocalClient} = cassandraClientCache.getClients()

  const [hookClient, setHookClient] = useState<ApolloClientType | undefined>(() => {
    let initialClient = privateClient
    if (isPublic) {
      initialClient = publicClient
    } else if (withBirdsong) {
      initialClient = privateLocalClient
    }
    return initialClient
  })

  useEffect(() => {
    if (isPublic || withBirdsong) {
      return
    }

    SubscribeToClient(setHookClient)

    setHookClient(privateClient)

    return () => {
      UnsubscribeToClient(setHookClient)
    }
  }, [isPublic, withBirdsong])

  return [hookClient, SetClient]
}
